import { Index } from 'o365.pwa.modules.shared.dexie.objectStores.Index.ts';
import { PrimaryKey } from 'o365.pwa.modules.shared.dexie.objectStores.PrimaryKey.ts';
import  type { O365PWACore } from 'o365.pwa.modules.client.dexie.databases.O365PWACore.ts';

export class ObjectStore {
    static objectStoreDexieSchema: string = "&[appId+databaseName+name],appId,name,databaseName";
    static o365PWACore: O365PWACore;

    public name!: string;
    public databaseName!: string;
    public appId!: string;
    public schema!: string; //change to proper type
    public indexes?: Index[];
    public primaryKeys?: PrimaryKey[];
    private databaseInstance: any;

    constructor(name: string, databaseName: string, appId: string, schema: string, ) {
        this.name = name;
        this.databaseName = databaseName;
        this.appId = appId;
        this.schema = schema;

        Object.defineProperty(this, "databaseInstance", {
            value: null,
            enumerable: false,
            writable: true,
        });
    }

    public async save() {
        return ObjectStore.o365PWACore.transaction("rw", ObjectStore.o365PWACore.indexes, ObjectStore.o365PWACore.primaryKeys, async () => {
            let [indexIds, primaryKeyIds] = await Promise.all([
                Promise.all( this.indexes.map(index => ObjectStore.o365PWACore.indexes.put(index)) ),
                Promise.all( this.primaryKeys.map(primaryKey => ObjectStore.o365PWACore.primaryKeys.put(primaryKey)) )
            ]);

            await Promise.all([
                ObjectStore.o365PWACore.indexes.where(["tableName, databaseName", "appId", "name"])
                    .equals([this.name, this.databaseName, this.appId])
                    .and(index => indexIds.indexOf(index.key) === -1)
                    .delete(),

                ObjectStore.o365PWACore.primaryKeys.where(["tableName, databaseName", "appId", "name"])
                    .equals([this.name, this.databaseName, this.appId])
                    .and(primaryKey => primaryKeyIds.indexOf(primaryKey.key) === -1)
                    .delete()
            ]);

            const newDatabaseTableResult = await ObjectStore.o365PWACore.objectStores.put(new ObjectStore(this.name, this.databaseName, this.appId, this.schema));
            this.name = newDatabaseTableResult[0];
            this.databaseName = newDatabaseTableResult[1];
            this.appId = newDatabaseTableResult[2];
            this.schema = newDatabaseTableResult[3];
            
        });
    }

    async loadIndexes() {
        return await ObjectStore.o365PWACore.indexes.where(["tableName", "databaseName", "appId"])
            .equals([this.name, this.databaseName, this.appId])
            .toArray(indexes => this.indexes = indexes);
    }

    async loadPrimaryKeys() {
        return await ObjectStore.o365PWACore.primaryKeys.where(["tableName", "databaseName", "appId"])
            .equals(this.name)
            .toArray(primaryKeys => this.primaryKeys = primaryKeys);
    }

        public async bulkCreateRecords(records: any[]): Promise<void> {
        this.databaseInstance[this.name].bulkPut(records);
       // this.databaseInstance.close();
    }

    public async retrieveRecords(): Promise<Array<Object>> {
        const records = await this.databaseInstance[this.name].toArray();
       // this.databaseInstance.close();
        return records;
    }

    public async retrieveRecord(uid: string): Promise<Object> {
        const record: any = await this.databaseInstance[this.name].get(uid);
       // this.databaseInstance.close();
        return record;
    }

    //TODO check how data api accepts/returns values
    public async updateRecord(record: any): Promise<any> {
        let primkey: string;

        primkey = await this.databaseInstance[this.name].put(record);

       // this.databaseInstance.close();
        const updatedRecord: any = await this.retrieveRecord(primkey);
        return updatedRecord;
    }

    //TODO check how data api accepts/returns values
    public async bulkUpdateRecords(records: any[]): Promise<any> {
        let primkeys:any []
       
        let [ids] = await this.databaseInstance[this.name].bulkPut(records);
        primkeys = ids;
        
       // this.databaseInstance.close();
        const updatedRecords: any = this.retrieveRecords();
        return updatedRecords;
    }

    public async createRecord(record: any): Promise<void> { // TODO: Look into having an partial interface for records
        await this.databaseInstance[this.name].add(record);
       //// this.databaseInstance.close();
    }

    public async destroyRecord(uid: string) {
        let returnValue;

        try {
            await this.databaseInstance[this.name].delete(uid);
            returnValue = uid;
        }
        catch(error) {
            returnValue = error;
        }
        
       //// this.databaseInstance.close();
        return returnValue;
    }

    public async bulkDestroyRecords(primkeys: string[]) {

        let returnValue;

        try {
            await this.databaseInstance[this.name].bulkDelete(primkeys);
            returnValue = primkeys;
        }
        catch(error) {
            returnValue = error;
        }
        
        //databaseInstance.close();
        return returnValue;
    }


}